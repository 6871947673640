<template>
  <lab-task>
    <lab-sim
      :script-src="scriptSrc"
      lab-name="titration-alpha-exercise-part5"
      v-bind="$attrs"
      v-on="$listeners"
      :seed="randomSeed"
      @allGuidanceComplete="submitResponse"
    />
  </lab-task>
</template>

<script>
import {CompletableSimMixin} from '../../mixins/completable-sim';
import {defineComponent, ref} from '@vue/composition-api';
import LabSim from '../simulations/LabSim.vue';
import LabTask from './labs/LabTask';
import {useAuthService} from '@/auth/services/authService';

export default defineComponent({
  name: 'OttawaTitrationSIMPart5',
  components: {LabSim, LabTask},
  mixins: [CompletableSimMixin()],
  data: () => ({
    scriptSrc: '/simulations/stable/ottawa-titration/stemble-lab.min.js',
  }),
  setup() {
    let randomSeed = ref(null);
    const auth = useAuthService();
    auth.fetchCurrentUser().then((result) => {
      randomSeed.value = result.id;
    });

    return {
      randomSeed,
    };
  },
});
</script>
